<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Introduction</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      <span class="subhead">A</span>  coordinate system is a means of specifying the position of an object.
      For example, imagine a billiards table, and consider a ball on this table.
      Now, if someone asks you to specify the location of the ball, how would you do this?
      <div style="margin-top: 20px" />
      You could say &mdash; the ball is located in the lower half of the table.
      <div style="margin-top: 20px" />
      Or, it is near the left edge of the board.
      <div style="margin-top: 20px" />
      Or, the ball is located at the lower left corner of the table.
      <div style="margin-top: 20px" />
      However, none of these statements describe the position of the ball precisely.
      <div style="margin-top: 10px" />
      <v-layout align-center
                justify-center
                row
                wrap
      >
        <v-flex xs12
                sm8
                md8
                lg8
                xl8
                class="font-weight-light"
                :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          Now, if you say &mdash; the ball is 18 inches away from the left edge, it gives
          a better idea of the location of the ball but
          still does not fix the position of the ball. <br><br> However, if you say the ball
          is located at 18 inches from the left edge and 12 inches from the bottom edge, it completely
          specifies the location of the ball on the table. <br><br> You may have noticed that the ball on the table can be located
          by specifying its distances from two perpendicular lines &mdash; the left edge of the table, and the bottom edge of the paper.
          <br><br>
          French philosopher and mathematician René Déscartes was the first to develop the idea of using a set of perpendicular lines (or axes) to specify the location of an object.<br><br>
          This idea, although very simple, has given rise to a very important
          branch of Mathematics known as Coordinate Geometry.<br><br>
          In this chapter, we will introduce some basic concepts of coordinate geometry.
          <div style="margin-top: 20px" />
        </v-flex>
        <v-flex xs12
                sm4
                md4
                lg4
                xl4
        >
          <v-img class="imgC"
                 padding="0px"
                 max-height="500px"
                 max-width="500px"
                 src="/assets/Rene.jpg"
                 contain
          />
          <br>
          <h5 style="text-align:start">
            French philosopher and mathematician René Déscartes developed the idea of using a set of two perpendicular lines to describe the position of a point.
          </h5>
        </v-flex>
      </v-layout>
      <div ref="ib" style="margin-top: 20px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Lesson Plan</span>
      </div>
      <div style="margin-top: 20px" />
      <div class="font-weight-light"
           :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        This chapter on coordinate system consists of three lessons. Each lesson comes with a visually interactive MagicGraph that offers students a uniquely engaging and effective learning experience.
        <div style="margin-top: 10px" />
        <h5> Lesson 1 | Understanding Cartesian Plane </h5>
        In this lesson, you will learn about Cartesian plane.
        <div style="margin-top: 10px" />
        <h5> Lesson 2 | Plotting a Point on Cartesian Plane </h5>
        In this lesson, you will learn about plotting a point on the Cartesian plane from its coordinates.
        <div style="margin-top: 10px" />
        <h5> Lesson 3 | Finding Coordinates of a Point </h5>
        In this lesson, you will learn about find the coordinates of a point from its location on the Cartesian.
      </div>
    </div>
    <div style="margin-top: 20px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          A coordinate system explained through the example of a billiards table.  The coordinates of the ball are measured relative to bottom (x-axis) and left (y-axis) edges of this table. You can move the ball on the billiards around and see its coordinates change.
        </h5>
      </v-layout>
    </v-responsive>
    <div style="margin-top: 20px" />
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div style="margin-top: 10px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Let's get started</span>
      </div>
      <div ref="ic" style="margin-top: 20px" />
      Check out our library of meticulously crafted lessons on coordinate system. These specially designed lessons use visuo-spatiality and interactivity to help students improve engagement and retain concepts.
      <div style="margin-top: 20px" />
      <v-container grid-list-xl text-xs-center>
        <v-layout
          align-center
          justify-center
          row
        >
          <v-flex v-for="topic in topics"
                  :key="topic.key"
                  xs12
                  sm12
                  md12
                  lg12
                  xl12
          >
            <subtopic-card
              :headline="topic.headline"
              :subheading="topic.subheading"
              :subsubheading="topic.subsubheading"
              :description="topic.description"
              :img="topic.img"
              :path="topic.path"
            />
          </v-flex>
        </v-layout>
      </v-container>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
import SubtopicCard from '../../../../views/SubTopics.vue'
export default {
    name: 'Vectors',
    components: {
    SubtopicCard
    },
    data () {
      return {
      topics: [
      {
        headline: "Lesson 1",
        img:"/assets/Mathematics-6th.svg",
        path: "/mathematics9th/coord/lesson-1"
      },
      { headline: "Lesson 2",
        img: "/assets/Mathematics-7th.svg",
        path: "/mathematics9th/coord/lesson-2"
      },
      {
       headline: "Lesson 3",
       img: "/assets/Mathematics-8th.svg",
       path: "/mathematics9th/coord/lesson-3"
     }
      ]
    }
  },
    created () {
      this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/changeTitle', 'Coordinate System');
      this.$store.commit('navigation/changeMenu', 'Coordinate System');
      let newTopics = [
        {title: 'Introduction', img:'/assets/wdice-1.svg', action: () => this.goto('ia') },
        {title: 'Lesson Plan', img:'/assets/wdice-2.svg', action: () => this.goto('ib') },
        {title: 'Get Started',img:'/assets/wdice-3.svg', action: () =>this.goto('ic')}
      ];
      this.$store.commit('navigation/replaceTopics', newTopics);
      this.$store.commit('navigation/toggleshowhome', false);
      this.$store.commit('navigation/replaceMath', true);
      this.$store.commit('navigation/replaceLeftArrow', true);
      this.$store.commit('navigation/replaceModule', true);
      this.$store.commit('navigation/replaceSample', true);
       },
  mounted () {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
      Boxes.box1();},
  metaInfo() {
      return{ title: 'Coordinate System',
              titleTemplate: '%s | Learn interactively',
              meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                      {name: 'description', content: 'Learn interactively about coordinate systems.'}
                    ]
            }
       }
}
</script>
<style scoped lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}
</style>
