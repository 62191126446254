// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeEraseLeft,
    placeStat,
    placeLabel,
    placeTapeHori,
    placeTapeVert,
    toggle,
    placeText,
    placeGo,
    placeStartOver
} from '../Utils';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 13, 13, -2], keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
	brd2.options.layer['point']=14;
  brd2.options.point.showInfoBox=false;
  placeLogo(brd2);
  makeResponsive(brd2);
  placeTitle(brd2, 'Coordinate System');
//  brd2.options.layer['image']=13;
//  brd2.options.layer['axis']=12;
brd2.create('text', [5.5, 11.5, 'Place the ball in a pockets to determine its coordinates'],{highlight:false, anchorX:'middle',anchorY:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}, fixed:true});
//
brd2.create('image', ['/assets/billiardsT.svg', [-1.85,-0.55], [10.75,11.08]], {visible: true, fixed: true, highlight:false});
//
var pts = brd2.create('point', [3.5, 2.5], {name:'', highlight:false, size:function(){return 14*brd2.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
var i=0;
var xcord=brd2.create('segment', [[()=>pts.X(), ()=>pts.Y()], [0, ()=>pts.Y()]],{visible:()=>i==0, dash:1, strokeWidth:1, strokeColor:'black'});
var ycord=brd2.create('segment', [[()=>pts.X(), ()=>pts.Y()], [()=>pts.X(), 0]],{visible:()=>i==0, dash:1, strokeWidth:1, strokeColor:'black'});

brd2.create('text', [3, -1, 'Bottom Edge'], {display:'internal', anchorX:'middle', fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
brd2.create('text', [-1, 5, 'Left Edge'], {rotate:90, display:'internal', anchorX:'middle', fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});

brd2.create('text', [10, 9.5, function(){return '' +(pts.X()).toFixed(2) + ' units right to the left edge';}], {anchorx:'middle',display:'internal',fixed:true, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

brd2.create('text', [10, 8.5, '&'], {anchorx:'middle',display:'internal',fixed:true, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
brd2.create('text', [10,7.5, function(){return '' +(pts.Y()).toFixed(2) + ' units above the bottom edge';}], {anchorx:'middle',display:'internal',fixed:true, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
brd2.create('text', [10,5.5, function(){return 'Coordinates = (' + (pts.X()).toFixed(2) + ' , ' + (pts.Y()).toFixed(2)+')';}], {anchorx:'middle',display:'internal',fixed:true, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
var hand = brd2.create('image', ['/assets/hands.svg', [function(){return pts.X()-1;}, function(){return pts.Y()-0.85;}], [1.25,1.25]], {highlight:false, visible:false});
pts.on('over', function(){hand.setAttribute({visible:true});});
pts.on('down', function(){i=1});
pts.on('out', function(){hand.setAttribute({visible:false});});
pts.on('up', function(){i=0});
brd2.create('text', [()=>pts.X()/2., ()=>pts.Y()+0.5, ()=>''+(pts.X()).toFixed(0)+' units'], {visible:()=>i==0, display:'internal', anchorX:'middle', anchorY:'bottom', fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
brd2.create('text', [()=>pts.X()+0.5, ()=>pts.Y()/2, ()=>''+(pts.Y()).toFixed(0)+' units'], {visible:()=>i==0, display:'internal', anchorX:'left', anchorY:'bottom', fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});

brd2.create('axis', [[0,0],[0,4]],{highlight:false, strokeColor:'grey', strokeWidth:2, name:'y', withLabel:true, label:{position:'urt',fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
brd2.create('axis', [[0,0],[4,0]],{highlight:false, strokeColor:'grey', strokeWidth:2, name:'x', withLabel:true, label:{position:'lrt', fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    },
}
export default Boxes;
